<template>
  <div>
    <div class="bg-white text-dark p-2 mb-3">
      <TInputCheckbox
        :checked="expected_payment_due_date_empty"
        @change="changeSwitch"
        :disabled="loading"
        label="No payment due date"
      />
    </div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="order.sale_orders"
      @click-clear-filter="clearFilter"
      resource="orders"
      reloadable
      @click-reload="refresh"
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 text-center" col="12">
          <TButtonEnter :to="lodash.getReferenceLink('order', item.id)" />
        </CCol>
      </template>
      <template #first_item="{ item }">
        <td>
          <SCardProductItems
            v-if="item.first_item"
            :item="item.first_item.product"
            resource="/sale/goods/products"
            :itemsCount="item.items_count"
            widthAuto
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #related_trackings="{ item }">
        <td class="text-truncate">
          <SMessageTracking
            v-if="!lodash.isEmpty(item.related_trackings)"
            :value="item.related_trackings[0].code"
            :checked="item.related_trackings[0].received"
          />
          <TMessageNotFound v-else />
          <TMessage
            v-if="item.related_trackings.length > 1"
            content="Tracking"
            lowercase
            size="small"
            color="muted"
            :truncate="1"
          >
            <template #prefix>
              +{{ item.related_trackings.length - 1 }}
            </template>
          </TMessage>
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <SMessageOrderStatus :id="item.status_id" />
        </td>
      </template>
      <template #customer_id="{ item }">
        <td>
          <TLink
            v-if="item.customer"
            :to="lodash.getReferenceLink('user', item.customer_id)"
            :content="item.customer.name"
          />
        </td>
      </template>
      <template #type="{ item }">
        <td>
          <SMessageOrderType :id="item.type_id" />
        </td>
      </template>

      <template #purchase_cost="{ item }">
        <td>
          <TMessageMoney
            :amount="item.purchase_cost"
            :currency="item.purchase_cost_currency_id"
            v-if="item.type_id != 'Shipment'"
          />
        </td>
      </template>
      <template #shipment_method_id="{ item }">
        <td>
          <SMessageShipmentMethod :id="item.shipment_method_id" />
        </td>
      </template>
      <template #transport_organization_id="{ item }">
        <td>
          <SMessageOrganization :id="item.transport_organization_id" />
        </td>
      </template>
      <template #sales_organization_id="{ item }">
        <td>
          <SMessageOrganization :id="item.sales_organization_id" />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" noTranslate />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>
      <template #payment_due_date="{ item }">
        <td>
          <TMessageDateTime :content="item.payment_due_date" dateOnly />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Order Id"
        />
      </template>
      <template #first_item-filter>
        <TInputText
          :value.sync="filter['items.product_id']"
          @update:value="filterChange"
          placeholder="Jancode"
        />
      </template>
      <template #related_trackings-filter>
        <TInputText
          :value.sync="filter['by_tracking_code']"
          @update:value="filterChange"
          placeholder="Tracking Id"
        />
      </template>
      <template #customer_id-filter>
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #shipment_method_id-filter>
        <SSelectShipmentMethod
          :value.sync="filter.shipment_method_id"
          @change="filterChange"
        />
      </template>
      <template #status-filter>
        <SSelectOrderStatus
          store="order.statuses"
          :value.sync="filter.status_id"
          @change="filterChange"
        />
      </template>
      <template #type-filter>
        <SSelectOrderType :value.sync="filter.type_id" @change="filterChange" />
      </template>
      <template #transport_organization_id-filter>
        <SSelectOrganization
          :value.sync="filter.transport_organization_id"
          @change="filterChange"
        />
      </template>
      <template #sales_organization_id-filter>
        <SSelectOrganization
          :value.sync="filter.sales_organization_id"
          @change="filterChange"
        />
      </template>
      <template #created_at-filter>
        <TInputDateTimeRange
          :value.sync="filter_between['created_at']"
          @update:value="filterChange"
        />
      </template>
      <template #payment_due_date-filter>
        <TInputDateTimeRange
          :value.sync="filter_between['payment_due_date']"
          @update:value="filterChange"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "id",
          label: "Order Id",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "related_trackings",
          label: "Tracking Id",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "type",
          label: "Order type",
          _classes: "text-nowrap",
          _style: "width: 120px; min-width: 120px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "first_item",
          label: "Product",
          _style: "width: 300px; min-width: 300px",
        },
        {
          key: "customer_id",
          label: "Customer",
          _classes: "text-nowrap",
          _style: "width: 170px; min-width: 170px",
        },
        {
          key: "purchase_cost",
          label: "Purchase cost",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "shipment_method_id",
          label: "Shipment method",
          _classes: "text-nowra",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "payment_due_date",
          label: "Payment due date",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "created_at",
          sorter: true,
          label: "Created at",
          _style: "width: 220px",
        },
        {
          key: "transport_organization_id",
          label: "Transportation company",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "sales_organization_id",
          label: "Purchasing company",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 160px; min-width: 160px",
        },
      ],
      filter: {},
      filter_between: {},
      expected_payment_due_date_empty: false,
    };
  },
  mounted() {
    this.$store.dispatch("order.sale_orders.fetch.if-first-time");
  },
  computed: {
    list() {
      return this.$store.getters[`order.sale_orders.list`];
    },
    loading() {
      return this.$store.getters[`order.sale_orders.loading`];
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("order.sale_orders.fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...this.formatFilterBetween(this.filter_between),
        "filter[expected_payment_due_date_empty]":
          this.expected_payment_due_date_empty,
      });
      this.$store.dispatch(`order.sale_orders.apply-query`, filterFields);
    },
    clearFilter() {
      this.filter_between = {};
      this.filter = {};
      this.filterChange();
    },
    changeSwitch(value) {
      this.expected_payment_due_date_empty = value;
      this.filterChange();
    },
    formatFilterBetween(obj) {
      return Object.entries(obj).reduce((p, c) => {
        p[`filter_between[${c[0]}]`] = [c[1].start || 0, c[1].end || 0].join(
          ","
        );
        return p;
      }, {});
    },
  },
};
</script>
